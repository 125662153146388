import { AppBar, Button, Container, Dialog, Grid, IconButton, Slide, Toolbar, Typography } from "@mui/material"
import { KuroroPickupList } from "../../../components/KuroroPickupList"
import { SelectedKuroro } from "../../../components/SelectedKuroro"
import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { initialTeamSlot, setAction, setSelectedKuroroTeamSlotState } from "../../../application/team-builder"
import React from "react"
import CloseIcon from '@mui/icons-material/Close';

import { TransitionProps } from "@mui/material/transitions"
import { useTeamBuilderService } from "../../../services/team-builder"
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const KuroroListPage = () => {
  const dispatch = useAppDispatch()
  const { resetSlot } = useTeamBuilderService()
  const selectedSlot = useAppSelector((state) => state.teamBuilder.selectedSlot)
  useEffect(() => {
    dispatch(setAction({
      action: 'list'
    }))
    dispatch(setSelectedKuroroTeamSlotState({
      kuroroTeamSlot: initialTeamSlot
    }))
  }, [dispatch])
  return <>
    <Container component="main" maxWidth="md">
      <Grid container style={{
        padding: '20px',
        justifyContent: 'center'
      }}>
        <Grid item xs={12}>
          <KuroroPickupList />
          <Dialog
            fullScreen
            open={selectedSlot.kuroro?.id ? true : false}
            onClose={() => {
              resetSlot()
            }}
            TransitionComponent={Transition}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    resetSlot()
                  }}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Kuroro Info
                </Typography>
                <Button autoFocus color="inherit" onClick={() => {
                  resetSlot()
                }}>
                  Close
                </Button>
              </Toolbar>
            </AppBar>
            <Container maxWidth="md" component={"main"}>

              <SelectedKuroro />
            </Container>

          </Dialog>
        </Grid>
      </Grid>
    </Container>
  </>
}