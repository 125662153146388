import { useCallback } from "react"
import { useAxiosInstance } from "../axios-instance"
import { IUser } from "../../application/user"
import { useAppSelector } from "../../hooks"
interface IUserApiModel {
  "id": number,
  "nickname": string
  "email": string
}
export const useUserApi = () => {
  const { axiosPublicInstance, axiosPrivateInstance } = useAxiosInstance()
  const token = useAppSelector((state) => state.auth.token)

  const createUserApi = useCallback((data: {
    nickname: string,
    email: string,
    password: string
  }): Promise<IUser> => {
    return axiosPublicInstance.post("/auth/register", data).then((res) => {
      return res.data
    }).catch((err) => {
      throw new Error(err.response.data.message)
    })
  }, [axiosPublicInstance])

  const getUserNicknameApi = useCallback(async (data: {
    nickname: string
  }) => {
    try {
      const response = await axiosPrivateInstance.get<IUserApiModel>(`/user/nickname/${data.nickname}`)
      console.log(token)
      return {
        nickname: response.data.nickname,
        email: response.data.email,
        id: response.data.id
      }
    } catch (err: any) {
      console.log('token',token)
      throw new Error(err.response.data.message)
    }
  }, [
    axiosPrivateInstance,
    token
  ])

  return {
    createUserApi,
    getUserNicknameApi
  }
}